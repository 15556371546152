import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import type { InputAddress } from '@dnc/baseline/services/vis-service';

/**
 * This component contains address component fields and is used in two places:
 *   1) the main IWV site, for looking up polling locations
 *   2) the IWV polling lookup widget
 *
 * Because the widget is publicly released for developer use, the CSS classnames
 * here are namespaced and are much more verbose in order to: -- be unique when
 * incorporated into third-party websites -- allow devs to have selectors to
 * style elements, if they desire to
 *
 * This is an uncontrolled component. It has form elements for
 * `street_address_1` and `zip`. Validation is done with the HTML5 `required`
 * and `pattern` attributes.
 *
 * Other notes: -- For ease of styling by external devs, <input> is not nested
 *   inside <label>. Because of this, we need to use the `for` and `id`
 *   attributes.
 */
const AddressFieldsBase = (prefix: string, defaultAddress?: InputAddress) => {
  return (
    <div className={`${prefix}-locate-form__fields`}>
      <div
        className={`${prefix}-field ${prefix}-field--required ${prefix}-locate-form__fields--street-address`}
      >
        <label
          className={`${prefix}-field__label`}
          htmlFor={`${prefix}-locate-form__fields--street-address`}
        >
          <FormattedMessage
            defaultMessage="Home street address"
            id="locate.searchForm.formFields.streetAddress"
            tagName="span"
          />
        </label>
        <div
          className={`${prefix}-field__hint`}
          id={`${prefix}-locate-form__hint--street-address`}
        >
          <FormattedMessage
            defaultMessage="For example: 100 Main St NW Apt A"
            id="locate.searchForm.formFieldHints.streetAddressExample"
          />
        </div>
        <input
          name="address_line_1"
          dir="ltr"
          aria-describedby={`${prefix}-locate-form__hint--street-address`}
          autoComplete="street-address"
          className={`${prefix}-field__input`}
          id={`${prefix}-locate-form__fields--street-address`}
          required
          type="text"
          defaultValue={defaultAddress?.addressLine1}
        />
      </div>
      <div
        className={`${prefix}-field ${prefix}-field--required ${prefix}-locate-form__fields--zip`}
      >
        <label
          className={`${prefix}-field__label`}
          htmlFor={`${prefix}-locate-form__fields--zip`}
        >
          <FormattedMessage
            defaultMessage="ZIP code"
            id="locate.searchForm.formFields.zip"
            tagName="span"
          />
        </label>
        <input
          name="zip"
          dir="ltr"
          autoComplete="postal-code"
          className={`${prefix}-field__input`}
          id={`${prefix}-locate-form__fields--zip`}
          maxLength={5}
          pattern="[0-9]{5}"
          required
          type="text"
          defaultValue={defaultAddress?.zipCode}
        />
      </div>
    </div>
  );
};

/**
 * Used by IWV
 */
const AddressFields: React.FC<{
  defaultAddress?: InputAddress;
}> = ({ defaultAddress }) => {
  return AddressFieldsBase('iwv', defaultAddress);
};

/**
 * Used by the locate widget
 */
const WidgetAddressFields: React.FC<{
  defaultAddress?: InputAddress;
}> = ({ defaultAddress }) => {
  return AddressFieldsBase('widget', defaultAddress);
};

export { AddressFields, WidgetAddressFields };
