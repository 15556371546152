import * as React from 'react';
import { render } from 'react-dom';
import { v4 as uuidv4 } from 'uuid';

import WidgetRoot, {
  type WidgetConfiguration,
} from '../components/locate/widget/WidgetRoot';

// Generate a fresh client ID on each widget startup
const getClientId = () => uuidv4();

const startApp = (config: WidgetConfiguration) => {
  const root = document.querySelector('#i-will-vote-widget');
  render(<WidgetRoot config={config} />, root);

  // Send widget view data to the backend
  const host = import.meta.env.VITE_VIS_BASE_URL as string;

  // Strip query strings for privacy reasons
  const cleanUrl = window.location.href.replace(/[?#].*$/, '');

  fetch(`${host}/widget/track-widget-view`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      clientId: getClientId(),
      url: cleanUrl,
      widgetType: 'locate',
    }),
  }).catch((error) => console.error('IWV analytics error:', error));
};

export const init = (config: WidgetConfiguration) => {
  startApp(config);
};
