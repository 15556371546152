import * as React from 'react';

import type { Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import {
  ActiveLanguageToLocale,
  type ActiveLocale,
} from '@dnc/baseline/utils/localization';

import { IwvIntlProvider } from '../../../i18n/i18n-config';

import WidgetForm from './WidgetForm';

export type WidgetConfiguration = {
  campaign?: string;
  locale?: ActiveLocale;
  state?: Jurisdiction;
  showHeader?: boolean;
};

type WidgetRootProps = {
  config?: WidgetConfiguration;
};

// We need a Root component for React Hot Loading.
class WidgetRoot extends React.Component<WidgetRootProps> {
  readonly searchFormUrl: string;

  readonly defaultLocale: ActiveLocale = ActiveLanguageToLocale['English'];

  constructor(props: WidgetRootProps) {
    super(props);

    this.searchFormUrl =
      import.meta.env.MODE === 'production'
        ? 'https://iwillvote.com/locate/results'
        : 'https://iwillvote-staging.go.akamai-access.com/locate/results';
  }

  render() {
    const { campaign, locale, showHeader, state } = this.props.config || {};

    const currentLocale = locale || this.defaultLocale;

    return (
      <IwvIntlProvider locale={currentLocale}>
        <WidgetForm
          campaign={campaign || ''}
          searchFormUrl={this.searchFormUrl}
          selectedState={state}
          showHeader={showHeader || false}
        />
      </IwvIntlProvider>
    );
  }
}

export default WidgetRoot;
