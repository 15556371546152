import * as React from 'react';

import type { Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import type { Option } from '@dnc/baseline/utils/option';

import { LocaleContext } from '@dnc/shared-components/LocaleContext';

import { WidgetAddressFields } from '../search-form/address-fields';
import { WidgetFormHeader } from '../search-form/form-header';
import { WidgetSearchActionButton } from '../utils/search-action-button';

type WidgetFormProps = {
  campaign: string;
  searchFormUrl: string;
  selectedState: Option<Jurisdiction>;
  showHeader: boolean;
};

const WidgetForm: React.FC<WidgetFormProps> = (props) => {
  const locale = React.useContext(LocaleContext);

  const { campaign, searchFormUrl, selectedState, showHeader } = props;

  return (
    <form
      action={searchFormUrl}
      className="widget-locate-form"
      method="get"
      target="_blank"
      lang={locale}
    >
      <input type="hidden" name="lang" value={locale} />
      <input type="hidden" name="state" value={selectedState} />
      <input type="hidden" name="utm_source" value={window.location.hostname} />
      <input type="hidden" name="utm_medium" value="iwv_widget" />
      <input type="hidden" name="utm_campaign" value={campaign} />
      <input type="hidden" name="client_id" value="widget" />
      {showHeader && <WidgetFormHeader />}
      <WidgetAddressFields />
      <WidgetSearchActionButton />
    </form>
  );
};

export default WidgetForm;
