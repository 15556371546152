import React from 'react';
import { FormattedMessage } from 'react-intl';

const SearchActionButtonBase: React.FC<{ className: string }> = ({
  className,
}) => (
  <button className={className} type="submit">
    <FormattedMessage
      defaultMessage="Search"
      description="Search as a call-to-action for a button"
      id="locate.searchForm.submitButton"
    />
  </button>
);

export const SearchActionButton: React.FC = () => (
  <SearchActionButtonBase className="iwv-button" />
);

export const WidgetSearchActionButton: React.FC = () => (
  <SearchActionButtonBase className="widget-button" />
);
